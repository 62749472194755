
	export default {
		data () {
			return {
				loading: {
					posPrinter: false,
					kotPrinter: false
				},
				printers: [],
				selectedPrinter: null,
				isPrinterConnected: false,
				paperSizes: [2, 3],
				qrPrintModes: [1, 2],
				selectedKots: [],
				kotDevices: []
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			kots () {
				return this.$store.state.kots
			},
			qrPrintMode: {
				get () {
					return this.$store.state.printerSettings.qrPrintMode
				},
				set (value) {
					this.$store.commit('setPrinterSettings', {
						...this.printerSettings,
						qrPrintMode: value
					})
				}
			}
		},
		watch: {
			selectedPrinter (value) {
				this.$store.commit('setPrinterSettings', {
					...this.printerSettings,
					name: value ? value.name : '',
					interface: value ? value.interface : ''
				})

				if (value) {
					this.checkPrinter(value)
				} else {
					this.isPrinterConnected = false
				}
			},
			selectedKots (newKots) {
				newKots = JSON.parse(this.objToJson(newKots))
				this.$store.commit('setState', {
					key: 'kots',
					value: newKots.map(kot => ({
						...kot,
						devices: this.kots.find(k => k.mac === kot.mac)?.devices || []
					})),
					save: true
				})

				const usbPrinter = newKots.find(kot => kot.interface === 'usb')

				if (usbPrinter) {
					this.checkPrinter(usbPrinter)
				}
			}
		},
		async beforeMount () {
			const kotDevices = await this.$bridge.getDevices(this.deviceId, this.locationId, this.objToJson({
				device_type_slug: 'kot'
			}))

			this.kotDevices = typeof kotDevices === 'string' ? JSON.parse(kotDevices) : kotDevices
			this.kotDevices = this.kotDevices.data || this.kotDevices

			if (this.printerSettings.interface) {
				this.selectedPrinter = {
					name: this.printerSettings.name,
					interface: this.printerSettings.interface
				}
			}

			this.selectedKots = JSON.parse(this.objToJson(this.kots))
		},
		destroyed () {
			delete window.getPrinters
		},
		methods: {
			async checkPrinter (printerSettings) {
				this.isPrinterConnected = await this.$bridge.isPrinterConnected(
					this.bridgeName === 'ANDROID' ? this.objToJson(printerSettings) : printerSettings
				)
			},
			getPrinters (type) {
				this.loading[`${type}Printer`] = true
				this.$bridge.getPrinters(type)

				window.getPrinters = (printers) => {
					this.printers = typeof printers === 'string' ? JSON.parse(printers) : printers
					this.loading[`${type}Printer`] = false
				}
			},
			testPrinter () {
				window.printOrder({
					id: this.getUniqueId(),
					receipt_code: '1801010001',
					ref_code: 'INV-0001',
					tables: [],
					customers: [],
					items: [{
						variation_id: 123,
						single_quantity_amount: 50,
						sub_total: 50,
						quantity: 1,
						variation_name: this.$t('item name'),
						groups: [],
						unit_measure_type: {}
					}],
					refunds: [],
					sub_total: 47.62,
					total_tax: 2.38,
					total_discounted_tax: 0,
					total_price: 50.00,
					payment_method_slug: 'cash',
					total_amount: 50.00,
					custom_attributes: {},
					updated_at: new Date()
				})
			},
			updatePrinterSettings ($event, name) {
				const printerSettings = Object.assign({}, this.printerSettings)

				if (typeof $event === 'number') {
					printerSettings.paperSize = $event
				} else if (typeof $event === 'string') {
					printerSettings.printerName = $event
				} else if (typeof $event === 'boolean') {
					printerSettings[
						name.replace(/-([a-z])/g, g => g[1].toUpperCase())
					] = $event
				} else if (typeof $event === 'object') {
					printerSettings[
						$event.target.name.replace(/-([a-z])/g, g => g[1].toUpperCase())
					] = $event.target.checked
				}

				this.$store.commit('setPrinterSettings', printerSettings)
			},
			updateKot (key, index, $event) {
				const kots = JSON.parse(this.objToJson(this.kots))

				kots[index][key] = $event

				if (key === 'isLabelPrinter' && $event) {
					kots[index].labelHeight = kots[index].labelHeight || 20
					kots[index].labelWidth = kots[index].labelWidth || 40
					kots[index].labelGap = kots[index].labelGap || 2
					kots[index].labelFontSize = kots[index].labelFontSize || 8
				}

				this.$store.commit('setState', {
					key: 'kots',
					value: kots,
					save: true
				})
			},
			mapKotDevice (index, devices) {
				const kots = JSON.parse(this.objToJson(this.kots))

				kots[index].devices = devices.map(device => ({
					id: device.id,
					name: device.name
				}))
				this.$store.commit('setState', {
					key: 'kots',
					value: kots,
					save: true
				})
			}
		}
	}
