
	let reminderInterval = null
	let activityTimer = null
	let timer = null

	export default {
		data () {
			return {
				tabs: {
					sell: true,
					tables: false,
					onlineOrders: false,
					advanceOrders: false,
					sales: false,
					reports: false,
					cash: false,
					customers: false,
					settings: false
				},
				componentKeys: {
					sell: 0,
					tables: 0,
					onlineOrders: 0,
					advanceOrders: 0,
					sales: 0,
					reports: 0,
					cash: 0,
					customers: 0,
					settings: 0
				},
				keystrokes: ''
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			partner () {
				return this.$store.state.partner
			},
			referrer () {
				return window.location.origin
			},
			locale () {
				return this.$store.state.locale
			},
			appVersion () {
				return this.$store.state.appVersion
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			parentMerchant () {
				return this.$store.state.merchant
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			selectedMerchant: {
				get () {
					return this.$store.state.selectedMerchant
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'selectedMerchant',
						value,
						save: true
					})
				}
			},
			merchants () {
				let merchants = []

				if (this.parentMerchant.childMerchants && this.parentMerchant.childMerchants.length) {
					merchants.push(this.$store.state.merchant)
					merchants = merchants.concat(this.$store.state.merchant.childMerchants)
				}

				return merchants
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			locationId () {
				return this.$store.state.locationId
			},
			locationName () {
				return this.$store.state.locationName
			},
			employee () {
				return this.$store.state.employee
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			employeeShift () {
				return this.$store.state.employeeShift
			},
			cashDrawer () {
				return this.$store.state.cashDrawer
			},
			cashDrawerShift () {
				return this.$store.state.cashDrawerShift
			},
			cart () {
				return this.$store.state.cart
			},
			loopNotificationSound () {
				return this.$store.state.loopNotificationSound
			},
			newOnlineOrdersCount: {
				get () {
					return this.$store.state.newOnlineOrdersCount
				},
				set (count) {
					this.$store.commit('setState', {
						key: 'newOnlineOrdersCount',
						value: count,
						save: true
					})
				}
			},
			advanceOrdersCount () {
				return this.$store.state.advanceOrdersCount
			},
			settings () {
				return this.$store.state.settings
			},
			tidyPay: {
				get () {
					return this.$store.state.tidyPay
				},
				set (value) {
					this.$store.commit('setState', { key: 'tidyPay', value })
				}
			},
			clover: {
				get () {
					return this.$store.state.clover
				},
				set (value) {
					this.$store.commit('setState', { key: 'clover', value })
				}
			},
			otpModalType: {
				get () {
					return this.$store.state.otpModalType
				},
				set (value) {
					this.$store.commit('setState', { key: 'otpModalType', value })
				}
			},
			keyboardShortcuts () {
				return this.$store.state.keyboardShortcuts
			},
			isOnline () {
				return this.$store.state.isOnline
			},
			customer: {
				get () {
					return this.$store.state.cart.customer
				},
				set (value) {
					this.$store.commit('setCart', { customer: value })
				}
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		watch: {
			employee () {
				this.componentKeys.reports++
				this.componentKeys.cash++
			},
			isOnline () {
				if (this.isOnline && !this.isMiniPlan) {
					this.syncAll({ force: false, silent: true })
					clearTimeout(timer)
					timer = setTimeout(() => {
						this.$axios.get('/api/online-orders/list', {
							params: {
								location_id: this.locationId,
								order_interim_state: 'placed',
								from_date: this.$moment.utc().startOf('day').format('DD-MM-YYYY HH:mm:ss'),
								to_date: this.$moment.utc().endOf('day').format('DD-MM-YYYY HH:mm:ss')
							}
						}).then((response) => {
							if (response.status === 200) {
								if (response.data.data.orders.length) {
									this.$root.$emit('get-online-orders')
									this.newOnlineOrdersCount = response.data.data.orders.length
									this.playNotificationSound()
								}
							}
						})
					}, 500)
				}
			}
		},
		mounted () {
			if (!this.isMiniPlan) {
				Object.assign(this.$socket.io.opts.query, {
					device_id: this.deviceId,
					channels: [`online-order-location:${this.locationId}`, `dirty-entity:${this.merchant.id}`]
				})

				this.$socket.connect()
				this.$root.$emit('crisp-toggle')
			}

			if (this.merchant.subscription && this.merchant.subscription.products.advance_ordering) {
				this.initAdvanceOrderReminder()
			}

			this.$root.$on('transfer-order-verified', this.showDrawerModal)

			if (this.settings.general.multi_employee_kiosk &&
				this.settings.general.multi_employee_kiosk_clockout_inactive) {
				this.resetActivityTimeout()
				window.addEventListener('touchmove', this.resetActivityTimeout)
				window.addEventListener('mousemove', this.resetActivityTimeout)
			}

			window.refreshTab = this.refreshTab
			window.Order = this.orderUpdateHandler
			this.$root.$on('update-cash-event', () => this.componentKeys.cash++)
			document.querySelector('.main-menu .nav-link')?.click()

			if (this.settings.general.auto_sync && !this.isMiniPlan) {
				this.$socket.on('dirty-entity:' + this.merchant.id, async (model) => {
					if (((Array.isArray(model.data?.location_id) && ((model.data.location_id.length === 0) || model.data.location_id.includes(this.locationId))) || (+(model.data?.location_id || this.locationId) === this.locationId)
					) && await this.getSyncOptions(model.entity)) {
						const record = this.appVersionNumber < 3839
							? await this.$bridge.getRecord('Sync', this.deviceId, this.objToJson([
								{ key: 'model_name', value: model.entity },
								{ key: 'payload', value: '{}' }
							]))
							: await this.$bridge.getRecord('Sync', this.objToJson([
								{ key: 'model_name', value: model.entity },
								{ key: 'payload', value: '{}' }
							]))

						if (!record) {
							const id = this.getUniqueId()
							const syncData = {
								id,
								model_id: id,
								model_name: model.entity,
								payload: '{}'
							}

							this.$bridge.insert(
								'Sync',
								this.bridgeName === 'ANDROID' ? this.objToJson(syncData) : syncData,
								true
							)
						}
					}
				})
			}

			if (this.employeePolicy.online_platform && this.employeePolicy.online_platform.includes('view')) {
				this.$store.dispatch('getAdvanceOrdersCount')
				this.$socket.on('online-order-location:' + this.locationId, async (event) => {
					if (event.type === 'new_order') {
						this.$root.$emit('get-online-orders')

						if (event.data.platform === 'advance') {
							this.$store.dispatch('getAdvanceOrdersCount')
						} else {
							++this.newOnlineOrdersCount
							this.playNotificationSound()
						}
					} else if (event.type === 'status_change') {
						if (
							(event.data.cancelled_by !== 'merchant' && event.data.state === 'cancelled') ||
							['cancelled', 'runner_cancelled'].includes(event.data.delivery_state)
						) {
							const ce = this.$createElement
							const messageNodes = ce(
								'div', { class: ['text-capitalize'] },
								[
									ce('p', { class: ['p-0', 'm-0'] }, [
										`${this.$t('cancelled by')}: ${
											event.data.delivery_state === 'runner_cancelled'
												? `delivery person (${event.data.delivery_service.name})`
												: event.data.cancelled_by || ''
										}`
									]),
									ce('p', { class: ['p-0', 'm-0'] }, [
										`${this.$t('previous state')}: ${
											event.data.state_history[event.data.state_history.length - 1].current_state
										}`
									])
								]
							)

							this.$bvToast.toast(messageNodes,
								{
									title: this.$t('onlineOrderCancelNotification', {
										0: event.data.channel.replace(/_/g, ' '),
										1: event.data.id
									}),
									variant: 'danger',
									toaster: 'b-toaster-top-right',
									autoHideDelay: 5000
								})
							this.playNotificationSound()

							let dbOrder = await this.$bridge.getOrders(this.deviceId, this.objToJson({
								id: event.data.id.toString()
							}))

							dbOrder = (typeof dbOrder === 'string' ? JSON.parse(dbOrder) : dbOrder).data[0]

							if (dbOrder && dbOrder.status !== 'cancelled' && event.data.state === 'cancelled') {
								const order = {
									id: this.getUniqueId(event.data.id),
									status: 'cancelled',
									updated_at: new Date()

								}

								await this.$bridge.insert(
									'Order',
									this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
									true
								)
							}
						} else if (event.data.platform === 'advance') {
							this.$store.dispatch('getAdvanceOrdersCount')
						} else if (
							event.data.order_type === 'dine_in' &&
							event.data.state === 'payment_authorized'
						) {
							const order = {
								id: this.getUniqueId(event.data.id),
								status: 'billed',
								tip: event.data.tip,
								total_price: event.data.total,
								updated_at: new Date()
							}

							await this.$bridge.insert(
								'Order',
								this.bridgeName === 'ANDROID' ? this.objToJson(order) : order,
								true
							)

							await this.$store.dispatch('updateTableStatus', event.data.custom_attributes.tables)

							if (this.tabs.tables) {
								this.$root.$emit('order-update')
							}
						}

						this.$root.$emit('online-order-status-change', event.data)
					}
				})
			}

			this.$socket.on(`device-debug-request:${this.deviceId}`, this.debug)
			document.addEventListener('keydown', this.keyboardEvents)
			this.setTabsShortcuts()
		},
		destroyed () {
			this.$socket.disconnect()
			clearTimeout(activityTimer)
			clearInterval(reminderInterval)
			window.removeEventListener('touchmove', this.resetActivityTimeout)
			window.removeEventListener('mousemove', this.resetActivityTimeout)
			delete window.refreshTab
			delete window.Order
			this.$root.$off('update-cash-event')
			document.removeEventListener('keydown', this.keyboardEvents)
		},
		methods: {
			async debug (debugInfo) {
				if (debugInfo.type === 'bridge' && this.$bridge[debugInfo.method]) {
					const response = await this.$bridge[debugInfo.method](...debugInfo.args)

					this.$socket.emit(`device-debug-response:${this.deviceId}`, {
						merchant_id: this.merchant.id,
						location_id: this.locationId,
						device_id: this.deviceId,
						employee_shift_code: this.employeeShift.shift_code,
						employee_id: this.employee?.id,
						cash_drawer_shift_code: this.cashDrawerShift?.shift_code,
						request: debugInfo,
						response
					})
				}
			},
			activateTab (tab) {
				for (const index in this.tabs) {
					this.tabs[index] = index === tab
				}
			},
			refreshTab (tab) {
				this.componentKeys[tab]++
			},
			setSelectedMerchant ($event) {
				const setMerchant = () => {
					this.selectedMerchant = $event
					this.componentKeys[Object.keys(this.tabs).find(key => this.tabs[key])]++
				}

				if (this.cart.items.length) {
					this.$swal({
						title: this.$t('cartNotEmptyWaring.title'),
						text: this.$t('cartNotEmptyWaring.text'),
						icon: 'warning',
						buttons: [this.$t('no'), this.$t('yes')],
						dangerMode: true
					}).then((response) => {
						if (response) {
							this.$store.commit('resetCart')
							setMerchant()
						} else {
							this.selectedMerchant = { ...this.selectedMerchant }
						}
					})
				} else {
					setMerchant()
				}
			},
			async clockout () {
				if (this.employee.id && this.cashDrawerShift.id) {
					let pendingOrders = await this.$bridge.getOrders(this.deviceId, this.objToJson({
						employee_shift_id: this.employeeShift.id,
						status: ['pending', 'billed']
					}))

					pendingOrders = (
						typeof pendingOrders === 'string' ? JSON.parse(pendingOrders) : pendingOrders
					)?.data.filter((o) => {
						return !o.custom_attributes.scheduled_at ||
							this.$moment.utc(o.custom_attributes.scheduled_at).local().isSame(new Date(), 'day')
					})

					if (pendingOrders?.length) {
						const tabs = []

						if (this.merchant.businessType === 'restaurant') {
							tabs.push(this.$tc('table', 2).toUpperCase())
						}

						if (this.merchant.subscription && this.merchant.subscription.products.online_store) {
							tabs.push(this.$t('online orders').toUpperCase())
						}

						if (this.merchant.subscription && this.merchant.subscription.products.advance_ordering) {
							tabs.push(this.$tc('advance order', 2).toUpperCase())
						}

						let tbodyContent = ''

						pendingOrders.forEach((order) => {
							const platform = order.channel || order.custom_attributes.platform

							tbodyContent = tbodyContent.concat(`<tr class="text-capitalize">
								<td>${order.custom_attributes.channel_id || order.order_id || order.receipt_code.substr(-10)}</td>
								<td>${platform ? platform.replace(/_/g, ' ') : `${this.$tc('table', (order.tables.length > 1) ? 2 : 1)}: ${order.tables.map(n => n.name).join(', ')}`}</td>
								<td>${order.status}</td>
							</tr>`)

							// fallback to handle when mismatch in table occupied status
							this.$store.dispatch('updateTableStatus', order.tables.map(t => t.id))
						})

						const messageNodes = this.$createElement(
							'div',
							{
								domProps: {
									innerHTML: `<div style="max-height: 250px; overflow-y: auto">
									<p>${this.$t('pendingOrdersError.text', [tabs.join(', ')])}</p>
									<table class="table mb-0">
										<thead>
											<tr class="text-capitalize">
												<th>${this.$tc('order', 1)} #</th>
												<th>${this.$t('type')}</th>
												<th>${this.$t('status')}</th>
											</tr>
										</thead>
										<tbody>
											${tbodyContent}
										</tbody>
									</table>
								</div>`
								}
							}
						)

						return this.$bvModal.msgBoxConfirm([messageNodes], {
							title: this.$tc('pendingOrdersError.title', pendingOrders.length > 1 ? 2 : 1, [pendingOrders.length]),
							okVariant: 'success',
							okTitle: this.$t(this.settings.general.shift_order_transfer ? 'transferToNextShift' : 'ok'),
							hideHeaderClose: false,
							centered: true
						}).then((res) => {
							if (res === true && this.settings.general.shift_order_transfer) {
								this.otpModalType = 'transfer-order'
							}
						}).catch(console.error)
					} else {
						this.showDrawerModal()
					}
				}
			},
			showDrawerModal () {
				this.$store.commit('setState', {
					key: 'cashDrawer',
					value: {
						show: true,
						close: true,
						type: 'closing'
					}
				})
			},
			switchEmployee (force) {
				if (this.appVersionNumber > 3821 && this.settings.general.multi_employee_kiosk) {
					clearTimeout(activityTimer)
					this.$store.dispatch('holdCart', true)
					this.$store.commit('setPasscode', { show: true, type: 'swap', force })
				}
			},
			resetActivityTimeout () {
				clearTimeout(activityTimer)
				activityTimer = setTimeout(() => {
					this.switchEmployee(true)
				}, +this.settings.general.multi_employee_kiosk_clockout_time * 60000) // timeout value in minutes, convert to milliseconds
			},
			playNotificationSound () {
				if (this.$refs.audio && this.$refs.audio.paused) {
					this.$refs.audio.play()
					this.$refs.audio.addEventListener('ended', (e) => {
						if (this.newOnlineOrdersCount && this.loopNotificationSound) {
							const audio = e.target

							audio.currentTime = 0
							setTimeout(() => audio.play(), 1500)
						}
					}, false)
				}
			},
			orderUpdateHandler (order) {
				if (typeof order === 'string') {
					order = JSON.parse(order)
				}

				this.$root.$emit('order-update')

				if (order) {
					order.taxes = typeof order.taxes === 'string' ? JSON.parse(order.taxes) : order.taxes
					order.discounts = typeof order.discounts === 'string' ? JSON.parse(order.discounts) : order.discounts
					order.charges = typeof order.charges === 'string' ? JSON.parse(order.charges) : order.charges
					order.custom_attributes = typeof order.custom_attributes === 'string' ? JSON.parse(order.custom_attributes) : order.custom_attributes
					order.items = order.items.map((i) => {
						i.groups = typeof i.groups === 'string' ? JSON.parse(i.groups) : i.groups
						i.taxes = typeof i.taxes === 'string' ? JSON.parse(i.taxes) : i.taxes
						i.discounts = typeof i.discounts === 'string' ? JSON.parse(i.discounts) : i.discounts
						i.custom_attributes = typeof i.custom_attributes === 'string' ? JSON.parse(i.custom_attributes) : i.custom_attributes

						return i
					})
				}

				if (order?.custom_attributes.channel_id && Array.isArray(order.custom_attributes.tables)) {
					if (this.bridgeName === 'ELECTRON') {
						order.items = order.items.map((i) => {
							i.item_variation_id = i.variation_id
							i.item_inventory_id = i.inventory_id
							i.merchant_price_category_id = order.price_category_id
							i.item_variation_name = i.variation_name
							i.single_quantity_amount = i.price
							i.discount_details = i.discounts
							i.tax_details = i.taxes
							i.total = i.sub_total + i.tax - i.discount

							return i
						})
					}

					this.$store.dispatch('updateOnlineOrder', {
						orderId: order.id,
						data: { ...order }
					})
				}
			},
			keyboardEvents (e) {
				if (
					[
						'AltLeft', 'AltRight', 'Digit1', 'Digit2', 'Digit3', 'Digit4', 'Digit5', 'Digit6', 'Digit7', 'Digit8', 'Digit9',
						'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9'
					].includes(e.code) &&
					!document.querySelector('.modal.show') &&
					!document.querySelector('.swal-overlay.swal-overlay--show-modal')
				) {
					this.keystrokes += e.code

					if (/^Alt/.test(this.keystrokes)) {
						if (/^Digit|^Numpad/.test(e.code)) {
							const tabList = document.getElementsByClassName('main-menu')[0]?.getElementsByClassName('tab')
							const tabIndex = e.code.replace(/Numpad|Digit/g, '')

							tabList[tabIndex - 1]?.focus()
							tabList[tabIndex - 1]?.click()
							this.keystrokes = ''
						}
					} else {
						this.keystrokes = ''
					}
				} else {
					this.keystrokes = ''
				}
			},
			initCrispChat () {
				if (!this.isOnline) {
					return this.$swal({
						title: this.$t('offlineError.title'),
						text: this.$t('offlineError.text'),
						icon: 'error',
						button: this.$t('ok')
					})
				}

				const crispPartnerId = this.partner?.crisp_id
				const user = this.merchant

				if (crispPartnerId) {
					if (window.$crisp) {
						window.$crisp.push(['do', 'chat:show'])
						window.$crisp.push(['do', 'chat:open'])
					} else {
						window.CRISP_WEBSITE_ID = crispPartnerId
						const s = document.createElement('script')

						s.src = 'https://client.crisp.chat/l.js'
						s.async = true

						s.onload = () => {
							setTimeout(() => {
								window.$crisp.push(['set', 'user:email', [user.email]])
								window.$crisp.push(['set', 'user:phone', [`${user.businessPhone.calling_code}-${user.businessPhone.number}`]])
								window.$crisp.push(['set', 'user:company', [user.brandName]])
								window.$crisp.push(['set', 'session:data', [[['merchant_id', user.id], ['plan', user.subscription.name], ['businessType', user.businessType]]]])
								window.$crisp.push(['set', 'session:segments', [[user.name], [user.businessType]]])
								window.$crisp.push(['config', 'position:reverse', [true]])
								window.$crisp.push(['on', 'chat:opened', () => {
									this.$root.$emit('crisp-toggle')
								}])
								window.$crisp.push(['on', 'chat:closed', () => {
									window.$crisp.push(['do', 'chat:hide'])
									this.$root.$emit('crisp-toggle')
								}])
								window.$crisp.push(['do', 'chat:open'])
							}, 1000)
						}

						document.getElementsByTagName('head')[0].appendChild(s)
					}

					this.$root.$emit('crisp-toggle')
				}
			},
			initAdvanceOrderReminder () {
				reminderInterval = setInterval(async () => {
					let advanceOrders = await this.$bridge.getData(
						'Order',
						`merchant_id = ${this.merchant.id} AND device_id = ${this.deviceId} AND location_id = ${this.locationId} AND custom_attributes CONTAINS[c] '"platform":"advance"' AND status = 'pending'`,
						-1,
						false
					)

					advanceOrders = typeof advanceOrders === 'string' ? JSON.parse(advanceOrders) : advanceOrders

					const count = advanceOrders.data.filter((order) => {
						return this.$moment.utc(order.custom_attributes.scheduled_at).local().isBetween(
							this.$moment(),
							this.$moment().add(+this.settings.general.advance_order_reminder_alert_time || 60, 'minutes')
						)
					}).length

					if (count > 0) {
						this.$bvToast.hide('advance-order-toast')
						this.$bvToast.toast(`There ${count > 1 ? 'are' : 'is'} ${count} order${count > 1 ? 's' : ''} pending that need to attention`, {
							title: this.$t('advanceOrderReminder'),
							id: 'advance-order-toast',
							variant: 'danger',
							toaster: 'b-toaster-top-right',
							noAutoHide: true,
							solid: true
						})
						this.playNotificationSound()
					} else {
						this.$bvToast.hide('advance-order-toast')
					}
				}, 60000)
			}
		}
	}
