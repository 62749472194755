
	import { jsPDF } from 'jspdf'
	import { IBMPlexSans } from '../assets/js/fonts'

	export default {
		props: {
			i18n: {
				type: Object,
				required: true
			},
			bridge: {
				type: Object,
				required: true
			},
			moment: {
				type: Function,
				required: true
			},
			currency: {
				type: Object,
				required: true
			},
			data: {
				type: Object,
				required: true
			}
		},
		data () {
			return {
				address: null,
				customerAddress: null,
				order: null,
				orderStates: [
					{
						text: 'order status',
						value: null
					},
					{
						text: 'pending',
						value: 'placed'
					},
					{
						text: 'confirm',
						value: 'confirmed',
						pluralizationIndex: 2
					},
					{
						text: 'ready',
						value: 'ready'
					},
					{
						text: 'assign',
						value: 'assigned',
						pluralizationIndex: 2
					},
					{
						text: 'dispatch',
						value: 'dispatched',
						pluralizationIndex: 2
					},
					{
						text: 'deliver',
						value: 'delivered',
						pluralizationIndex: 2
					},
					{
						text: 'complete',
						value: 'completed',
						pluralizationIndex: 2
					},
					{
						text: 'cancel',
						value: 'cancelled',
						pluralizationIndex: 2
					},
					{
						text: 'refund',
						value: 'refunded',
						pluralizationIndex: 3
					}
				]
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			partner () {
				return this.$store.state.partner
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			merchant () {
				return this.$store.state.merchant
			},
			location () {
				return this.$store.state.location
			},
			settings () {
				return this.$store.state.settings
			},
			employee () {
				return this.$store.state.employee
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			showActualSubtotal () {
				return (
					!this.order.custom_attributes.tax_calculation_phase ||
					this.order.custom_attributes.tax_calculation_phase === 'before_discount'
				) && this.settings.general.include_tax_in_subtotal
			},
			computedPrice () {
				const price = {
					subtotal: this.order.sub_total,
					tax: this.order.total_tax
				}

				if (this.showActualSubtotal) {
					price.subtotal -= this.order.total_discounted_amount
					price.tax -= this.order.total_discounted_tax
				}

				return price
			},
			creditPaymentAmount () {
				return (this.order.order_payments || this.order.payment_methods).reduce((totalCredit, pm) => {
					if (pm.slug === 'credit') {
						totalCredit += pm.amount
					}

					return totalCredit
				}, 0)
			},
			amountSavedOnMrp () {
				return this.order.items.reduce((sum, item) => {
					sum += (item.mrp ? item.mrp : (item.price || item.single_quantity_amount)) * item.quantity

					return sum
				}, 0) - this.order.total_price
			}
		},
		beforeMount () {
			const order = JSON.parse(this.objToJson(this.data.order))
			let allRefundedItems = []

			order.refunds = order.refunds || []

			if (order.refund) {
				order.refunds = [{
					...order.refund
				}]
			}

			const totalCharges = (order.charges && order.charges.reduce((sum, charge) => {
				sum += charge.amount + charge.tax

				return sum
			}, 0)) || 0

			const orderTotal = this.currency.transformNumber(order.refunds.reduce((sum, refund) => {
				sum += refund.amount

				return sum
			}, 0) + order.tip)

			if (order && order.refunds.length) {
				if (orderTotal === order.total_price) {
					order.refund_type = 'full'
				} else if (this.currency.transformNumber(orderTotal + totalCharges) >= order.total_price) {
					order.refund_type = 'full'
					order.total_price -= totalCharges
				} else if (this.currency.transformNumber(orderTotal - totalCharges) === order.total_price) {
					order.refund_type = 'full'
					order.total_price += totalCharges
				} else {
					order.refund_type = 'partial'
				}
			}

			if (order) {
				if (+this.settings.receipt.print_items_alpha_order) {
					order.items.sort((a, b) => {
						const aName = (a.variation_name || a.item_variation_name).toLowerCase()
						const bName = (b.variation_name || b.item_variation_name).toLowerCase()

						return aName > bName ? 1 : (aName < bName ? -1 : 0)
					})
				}

				if (order.refunds && order.refunds.length) {
					order.refunds.forEach((refund) => {
						refund.items.forEach((item) => {
							const orderItem = order.items.find((i) => {
								return i.item_code === item.item_code
							})

							item.variation_name = orderItem.variation_name || orderItem.item_variation_name
							item.alternate_name = orderItem.alternate_name
							item.mrp = orderItem.mrp
							item.quantity = orderItem.quantity
							item.price = orderItem.price || orderItem.single_quantity_amount
							item.groups = orderItem.groups
							allRefundedItems.push(item) // All refunded items are pushed in array
						})
					})

					allRefundedItems = Object.values(allRefundedItems.reduce((acc, curr) => { // Unique refuned items are aggregated and added with the quantity refuneded
						if (!acc[curr.item_code]) {
							acc[curr.item_code] = curr
						} else {
							acc[curr.item_code] = {
								...curr,
								refunded_qty: curr.refunded_qty + acc[curr.item_code].refunded_qty,
								refunded_amount: curr.refunded_amount + acc[curr.item_code].refunded_amount,
								refunded_tax: curr.refunded_tax + acc[curr.item_code].refunded_tax
							}
						}

						return acc
					}, {}))

					order.refunds = order.refunds.map((orderRefund) => {
						return {
							...orderRefund,
							items: orderRefund.items.map((refundItem) => {
								const orderItem = order.items.find(i => i.item_code === refundItem.item_code)

								if (orderItem) {
									refundItem = {
										...orderItem,
										...refundItem
									}
								}

								return refundItem
							})
						}
					})

					order.total_refund_amount = order.refunds.reduce((sum, refund) => {
						sum += refund.amount

						return sum
					}, 0)
					order.total_price = order.total_price - (order.tip || 0)

					if (this.settings.general.round_off_total &&
						order.total_price !== this.data.order.total_price) {
						order.round_off_amount = Math.round(order.total_price) - order.total_price
						order.total_price = Math.round(order.total_price)
					}
				}

				order.taxable_items = order.items.reduce((items, item) => {
					let taxes = []

					item.taxes = item.taxes && typeof item.taxes === 'string' ? JSON.parse(item.taxes) : item.taxes
					item.tax_details = item.tax_details && typeof item.tax_details === 'string' ? JSON.parse(item.tax_details) : item.tax_details
					taxes = [...(item.taxes || item.tax_details || [])]

					if (taxes.length > 0) {
						delete (item.taxes || item.tax_details)

						items.push({
							...item,
							taxes
						})
					}

					return items
				}, [])

				order.discounted_items = order.items.reduce((items, item) => {
					const discounts = [...(item.discounts || item.discount_details || [])]

					if (discounts.length > 0) {
						delete (item.discounts || item.discount_details)

						items.push({
							...item,
							discounts
						})
					}

					return items
				}, [])

				order.refundedItems = allRefundedItems
				this.order = order
			}

			this.address = this.getAddress(this.location.address)

			if (!this.address) {
				this.address = this.getAddress(this.merchant.businessAddress)
			}

			this.customerAddress = this.order.customer ? this.getAddress(this.order.customer.address) : this.customerAddress
		},
		methods: {
			getOrderStatus (state) {
				const orderState = this.orderStates.find(s => s.value === state)

				return orderState ? this.i18n.tc(orderState.text, orderState.pluralizationIndex || 1) : state
			},
			getAddress (obj) {
				const address = []

				if (obj.address_line1) {
					address.push(obj.address_line1)
				}

				if (obj.address_line2) {
					address.push(obj.address_line2)
				}

				if (obj.address_line_1) {
					address.push(obj.address_line_1)
				}

				if (obj.address_line_2) {
					address.push(obj.address_line_2)
				}

				if (obj.line) {
					address.push(obj.line)
				}

				if (obj.area) {
					address.push(obj.area)
				}

				if (obj.city) {
					address.push(obj.city)
				}

				if (obj.state) {
					address.push(obj.state)
				}

				if (obj.region) {
					address.push(obj.region)
				}

				if (obj.country) {
					address.push(obj.country)
				}

				if (obj.postal_code) {
					address.push(obj.postal_code)
				}

				if (obj.zip) {
					address.push(obj.zip)
				}

				return address.join(', ')
			},
			renderInvoice () {
				const iframe = document.getElementById(this.data.id)
				const iframeDocument = iframe.contentDocument
				const el = iframeDocument.getElementById('invoice')

				// eslint-disable-next-line
				const doc = new jsPDF('p', 'pt', 'a4')

				doc.addFileToVFS('IBMPlexSans.ttf', IBMPlexSans)
				doc.addFont('IBMPlexSans.ttf', 'IBMPlexSans', 'normal')
				doc.setFont('IBMPlexSans')

				doc.html(el, {
					margin: [30, 5, 30, 5],
					html2canvas: {
						scale: 0.47
					},
					callback: (doc) => {
						const dataURL = doc.output('datauristring').replace(/^data:application\/(pdf);filename=generated.pdf;base64,/, '')
						const fileName = `${this.data.id}.pdf`
						const options = { fileName, dataURL }

						options.print = this.data.type === 'print'
						this.bridge.downloadFile(
							this.bridgeName === 'ANDROID' ? this.objToJson(options) : options
						)
						this.$emit('destroy')
					}
				})
			}
		}
	}
