
	export default {
		data () {
			return {
				tabs: {
					application: true,
					printer: false,
					sync: false,
					scanner: false,
					weighingScale: false,
					internationalBancard: false,
					tidyPay: false,
					skyband: false,
					pineLabs: false,
					clover: false,
					keyboard: false,
					system: false,
					onlinePlatform: false,
					import: false,
					// device: false,
					employee: false,
					floors: false,
					tables: false,
					taxes: false,
					charges: false,
					discount: false,
					brand: false,
					category: false,
					priceCategory: false
				},
				loading: {
					system: false
				},
				lastOrderCount: 0,
				categoryViewOptions: ['button', 'select'],
				archivalDays: [...Array(24).keys()].map((i) => {
					const num = i + 7

					return {
						text: `${num} ${this.$tc('day', 2)}`,
						value: num
					}
				}),
				syncDetailsKey: 0,
				printerDetailsKey: 0,
				deviceInfo: {
					cpu: '',
					os: {},
					system: {}
				},
				applicationInfo: {},
				merchantLogo: null,
				displays: []
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			appVersion () {
				return this.$store.state.appVersion
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			device () {
				return this.$store.state.device
			},
			primaryDevice () {
				return this.$store.state.primaryDevice
			},
			uuid () {
				return this.$store.state.uuid
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			categoryView: {
				get () {
					return this.$store.state.categoryView
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'categoryView',
						value,
						save: true
					})
				}
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			location () {
				return this.$store.state.location
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			showOrderConfirmation: {
				get () {
					return this.$store.state.showOrderConfirmation
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'showOrderConfirmation',
						value,
						save: true
					})
				}
			},
			showConfirmWOPrint: {
				get () {
					return this.$store.state.showConfirmWOPrint
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'showConfirmWOPrint',
						value,
						save: true
					})
				}
			},
			loopNotificationSound: {
				get () {
					return this.$store.state.loopNotificationSound
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'loopNotificationSound',
						value,
						save: true
					})
				}
			},
			kitchenDisplayZoomLevel: {
				get () {
					return this.$store.state.kitchenDisplayZoomLevel
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'kitchenDisplayZoomLevel',
						value,
						save: true
					})
				}
			},
			archivalFrequency: {
				get () {
					return this.$store.state.archivalFrequency
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'archivalFrequency',
						value,
						save: true
					})
				}
			},
			settings () {
				return this.$store.state.settings
			},
			isWaiterAppRunning () {
				return this.$store.state.isWaiterAppRunning
			},
			selectedSecondaryDisplay: {
				get () {
					return this.$store.state.selectedSecondaryDisplay
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'selectedSecondaryDisplay',
						value,
						save: true
					})

					this.$bridge.loadSecondaryDisplay()
				}
			},
			showItemSearchDropdown: {
				get () {
					return this.$store.state.showItemSearchDropdown
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'showItemSearchDropdown',
						value,
						save: true
					})
				}
			},
			isMiniPlan () {
				return this.$store.state.merchant.subscription.slug === 'mini'
			}
		},
		async beforeMount () {
			this.getLastOrderCount()
			this.merchantLogo = await this.$bridge.getLocalStorage(`merchant${this.merchant.id}Logo`)

			if (this.$bridge.getExternalDisplays) {
				this.displays = [...(await this.$bridge.getExternalDisplays()).map((d, index) => { return { ...d, label: `Display ${index + 1}` } })]
			}
		},
		methods: {
			async getLastOrderCount () {
				if (this.device.is_primary) {
					this.lastOrderCount = +(await this.$bridge.getLocalStorage('orderCount'))
				}
			},
			getPrimaryDevice () {
				this.$store.dispatch('getPrimaryDevice', true)
			},
			async activateTab (tab) {
				if (Object.prototype.hasOwnProperty.call(this.loading, tab)) {
					this.loading[tab] = true
				}

				for (const index in this.tabs) {
					this.tabs[index] = index === tab
				}

				if (tab === 'system') {
					let deviceInfo = await this.$bridge.getDeviceInfo()
					const applicationInfo = await this.$bridge.getApplicationInfo()

					deviceInfo = typeof deviceInfo === 'string'
						? JSON.parse(deviceInfo)
						: deviceInfo
					this.applicationInfo = typeof applicationInfo === 'string'
						? JSON.parse(applicationInfo)
						: applicationInfo

					if (this.appVersionNumber < 4010) {
						const defaultNetworkInterface = await this.$bridge.getDefaultNetworkInterface()

						deviceInfo.system.ip = (
							typeof defaultNetworkInterface === 'string'
								? JSON.parse(defaultNetworkInterface)
								: defaultNetworkInterface
						).ip4
					}

					this.deviceInfo = deviceInfo
				}

				if (Object.prototype.hasOwnProperty.call(this.loading, tab)) {
					this.loading[tab] = false
				}
			},
			startStopWaiterApp ($event) {
				this.$store.commit('setState', {
					key: 'isWaiterAppRunning',
					value: $event.target.checked,
					save: true
				})

				if ($event.target.checked) {
					this.$bridge.startWaiterApp()
				} else {
					this.$bridge.stopWaiterApp()
				}
			}
		}
	}
