
	export default {
		props: {
			i18n: {
				type: Object,
				required: true
			},
			bridge: {
				type: Object,
				required: true
			},
			moment: {
				type: Function,
				required: true
			},
			currency: {
				type: Object,
				required: true
			},
			domtoimage: {
				type: Object,
				required: true
			},
			data: {
				type: Object,
				required: true
			}
		},
		data () {
			return {
				employee: {},
				cashDrawerShift: {},
				itemSummary: [],
				refundSummary: [],
				netCash: 0,
				cashDifference: 0
			}
		},
		computed: {
			bridgeName () {
				return this.$store.state.bridgeName
			},
			locale () {
				return this.$store.state.locale
			},
			partner () {
				return this.$store.state.partner
			},
			deviceId () {
				return this.$store.state.deviceId
			},
			appVersionNumber () {
				return this.$store.getters.appVersionNumber
			},
			merchant () {
				return this.$store.state.merchant
			},
			selectedMerchant () {
				return this.$store.state.selectedMerchant
			},
			location () {
				return this.$store.state.location
			},
			settings: {
				get () {
					return this.$store.state.settings
				},
				set (value) {
					this.$store.commit('setState', {
						key: 'settings',
						value,
						save: true
					})
				}
			},
			denominations () {
				return this.$store.state.denominations
			},
			printerSettings () {
				return this.$store.state.printerSettings
			},
			employeePolicy () {
				return this.$store.getters.employeePolicy
			},
			paymentMethodSales () {
				return this.data.salesReport.paymentMethodSales || this.data.salesReport.paymentMethods
			},
			taxSales () {
				return this.data.salesReport.tax || this.data.salesReport.taxes
			},
			tipSales () {
				return this.data.salesReport.tip || this.data.salesReport.tips
			},
			channelSales () {
				return this.data.salesReport.channelSales || this.data.salesReport.channels
			},
			employeeSales () {
				return this.data.salesReport.employeeSales || this.data.salesReport.employees
			},
			creditSales () {
				return this.data.salesReport.creditSales || this.data.salesReport.credits
			}
		},
		beforeMount () {
			this.employee = Object.assign({}, this.$store.state.employee)
			this.cashDrawerShift = Object.assign({}, this.data.cashDrawerShift || this.$store.state.cashDrawerShift)
			this.itemSummary = this.data.itemsSalesReport.length ? this.data.itemsSalesReport : this.data.itemsSalesReport.sales
			this.itemSummary = this.settings.receipt.print_category_wise_item_summary
				? this.groupBy(this.itemSummary, 'category_name')
				: this.itemSummary
			this.refundSummary = this.data.itemsSalesReport.refunds || []
			this.refundSummary = this.settings.receipt.print_category_wise_item_summary
				? this.groupBy(this.refundSummary, 'category_name')
				: this.refundSummary
			this.netCash = this.cashDrawerShift.starting_cash_amount + (
				this.getEventData('cash_in').sum - this.getEventData('cash_out').sum
			)

			if (this.cashDrawerShift.closed_cash_amount >= 0) {
				this.cashDifference = this.currency.transformNumber(this.cashDrawerShift.closed_cash_amount - this.netCash)
			}

			if (this.settings.receipt) {
				this.settings.receipt.print_cash_summary = typeof this.settings.receipt.print_cash_summary === 'boolean' ? this.settings.receipt.print_cash_summary : true
				this.settings.receipt.print_employee_summary = typeof this.settings.receipt.print_employee_summary === 'boolean' ? this.settings.receipt.print_employee_summary : true
				this.settings.receipt.print_payment_summary = typeof this.settings.receipt.print_payment_summary === 'boolean' ? this.settings.receipt.print_payment_summary : true
				this.settings.receipt.print_shift_summary = typeof this.settings.receipt.print_shift_summary === 'boolean' ? this.settings.receipt.print_shift_summary : true
			}
		},
		mounted () {
			if (this.data.source === 'clockout') {
				this.$store.commit('setState', { key: 'employeeShift', value: null, save: false })
				this.$store.commit('setState', { key: 'cashDrawerShift', value: null, save: false })
				this.$store.commit('setState', { key: 'employee', value: null, save: false })
				this.$store.commit('setState', { key: 'selectedMerchant', value: null, save: false })
				this.$store.commit('resetPasscode')
			}
		},
		methods: {
			getEventData (type, cashVia) {
				const events = this.data.cashDrawerShiftEvents.filter((event) => {
					return event.type === type && (!cashVia || (cashVia && event.cash_via === cashVia))
				})

				return {
					count: events.length,
					sum: events.length ? events.reduce((sum, event) => sum + event.amount, 0) : 0
				}
			},
			renderReceipt () {
				const iframe = document.getElementById(this.data.id)
				const iframeDocument = iframe.contentDocument
				const el = iframeDocument.getElementById('receipt')
				const height = el.offsetHeight * this.printerSettings.scaleFactor
				const width = el.offsetWidth * this.printerSettings.scaleFactor

				this.domtoimage.toPng(el, {
					height,
					width
				}).then((dataURL) => {
					if (process.env.NODE_ENV !== 'production') {
						// eslint-disable-next-line
						console.log('%c ', `font-size: 1px; padding: ${Math.floor(height / 2)}px ${Math.floor(width / 2)}px; background: url(${dataURL})`)
					}

					const printData = {
						dataURL,
						printerSettings: this.printerSettings,
						fileName: `${this.data.id}.png`
					}

					if (this.data.type === 'download') {
						const options = {
							fileName: printData.fileName,
							dataURL: dataURL.replace(/^data:image\/png;base64,/, '')
						}

						this.bridge.downloadFile(
							this.bridgeName === 'ANDROID' ? this.objToJson(options) : options
						)
					} else {
						this.bridge.pushPrinterQueue(
							this.bridgeName === 'ANDROID' ? this.objToJson(printData) : printData
						)
					}

					if (process.env.NODE_ENV === 'production' && this.data.type !== 'download') {
						this.$store.dispatch('uploadToS3', {
							type: 'image',
							merchantId: this.merchant.id,
							locationId: this.location.id,
							key: `shifts/${this.data.employeeShift.shift_code}`,
							dataURL
						}).catch(console.error)
					}
				}).catch(console.error).finally(() => {
					this.$emit('destroy')
				})
			}
		}
	}
