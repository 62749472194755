import { render, staticRenderFns } from "./cart.vue?vue&type=template&id=176fb6a6"
import script from "./cart.vue?vue&type=script&lang=js"
export * from "./cart.vue?vue&type=script&lang=js"
import style0 from "./cart.vue?vue&type=style&index=0&id=176fb6a6&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DiscountModal: require('/home/ubuntu/complete-nuxt-js/components/discount-modal.vue').default,DynamicDiscountModal: require('/home/ubuntu/complete-nuxt-js/components/dynamic-discount-modal.vue').default,ItemNotesModal: require('/home/ubuntu/complete-nuxt-js/components/item-notes-modal.vue').default,ChargesModal: require('/home/ubuntu/complete-nuxt-js/components/charges-modal.vue').default,GiftCardInput: require('/home/ubuntu/complete-nuxt-js/components/gift-card-input.vue').default})
