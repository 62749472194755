
	import { ValidationObserver, ValidationProvider } from 'vee-validate'

	export default {
		components: {
			ValidationObserver,
			ValidationProvider
		},
		data () {
			return {
				otp: null,
				token: null,
				timerCount: 0,
				selectedCustomer: {}
			}
		},
		computed: {
			locale () {
				return this.$store.state.locale
			},
			merchant () {
				return this.$store.state.selectedMerchant || this.$store.state.merchant
			},
			customer () {
				return this.$store.state.cart.customer
			}
		},
		methods: {
			init () {
				if (this.customer) {
					this.selectedCustomer = JSON.parse(this.objToJson(this.customer))
					this.sendOtp()
				}
			},
			cancelCustomerConsent () {
				this.$emit('update:customer', this.selectedCustomer)
				this.$refs.customerOtpVerification.hide()
			},
			sendOtp () {
				this.token = null

				if (this.$offline.state === 'up') {
					this.$axios.post('/api/pos/v2/otp-services/send', {
						phone: this.selectedCustomer.phone,
						calling_code: this.merchant.businessPhone.calling_code,
						type: 'consent'
					}).then((response) => {
						if (response.data.status === 'success') {
							this.token = response.data.data.token
						}

						this.setTimerCountDown()
					}).catch((err) => {
						if (err.response && this.$refs.validator) {
							this.$refs.validator.setErrors({
								otp: [err.response.data.message]
							})
						}
					})
				}
			},
			setTimerCountDown () {
				this.timerCount = 60
				const countDown = setInterval(() => {
					this.timerCount--

					if (this.timerCount < 0) {
						clearInterval(countDown)
					}
				}, 1000)
			},
			async submitForm () {
				if (await this.$refs.validator.validate()) {
					this.$axios.post('/api/pos/v2/otp-services/verify', {
						token: this.token,
						otp: this.otp
					}).then((response) => {
						if (response.data.status === 'success') {
							this.selectedCustomer.otp_verified = true
							this.$emit('update:customer', this.selectedCustomer)
							this.$refs.customerOtpVerification.hide()
						}
					}).catch((err) => {
						if (err.response) {
							this.$refs.validator.setErrors({
								otp: [err.response.data.message]
							})
						}
					})
				}
			},
			cleanUp () {
				this.otp = null
				this.timerCount = 0
				this.selectedCustomer = null
			}
		}
	}
